var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "elementPanel" },
    [
      _vm.$store.state.Auth.hasRole(_vm.userRoles.ManagerRoles)
        ? _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs1: "" } },
                [
                  _c("v-switch", {
                    staticClass: "mt-0 pt-0",
                    attrs: { color: "#837f16", "hide-details": "" },
                    on: {
                      change: function($event) {
                        return _vm.refreshCalculations()
                      }
                    },
                    model: {
                      value: _vm.useCalculationModel5,
                      callback: function($$v) {
                        _vm.useCalculationModel5 = $$v
                      },
                      expression: "useCalculationModel5"
                    }
                  })
                ],
                1
              ),
              _c("v-flex", { attrs: { xs11: "" } }, [
                _vm._v("Prijzen in v5 model bekijken")
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.isLoading
        ? _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("LoaderCard", {
                    attrs: {
                      flat: "",
                      type: "spinner--center",
                      minHeight: "300px"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      !_vm.isLoading && _vm.total_repairs
        ? [
            _c(
              "v-tabs",
              {
                attrs: { "slider-color": "secondary" },
                model: {
                  value: _vm.activeTab,
                  callback: function($$v) {
                    _vm.activeTab = $$v
                  },
                  expression: "activeTab"
                }
              },
              [
                _c(
                  "v-tab",
                  {
                    staticClass: "tab__filled",
                    class: { active: _vm.activeTab === "tab-conclusie" },
                    attrs: { href: "#tab-conclusie" }
                  },
                  [_vm._v(" Conclusie ")]
                ),
                _vm.$store.state.Auth.has_management_info
                  ? _c(
                      "v-tab",
                      {
                        staticClass: "tab__filled",
                        class: { active: _vm.activeTab === "tab-damages" },
                        attrs: { href: "#tab-damages" }
                      },
                      [_vm._v(" Schades ")]
                    )
                  : _vm._e(),
                _c(
                  "v-tab",
                  {
                    staticClass: "tab__filled",
                    class: { active: _vm.activeTab === "tab-costs" },
                    attrs: { href: "#tab-costs" }
                  },
                  [_vm._v(" Opstartkosten ")]
                ),
                _c(
                  "v-tab",
                  {
                    staticClass: "tab__filled",
                    class: { active: _vm.activeTab === "tab-repairs" },
                    attrs: { href: "#tab-repairs" }
                  },
                  [_vm._v(" Reparaties ")]
                ),
                _c(
                  "v-tab",
                  {
                    staticClass: "tab__filled",
                    class: {
                      active: _vm.activeTab === "tab-aannemersbegroting"
                    },
                    attrs: { href: "#tab-aannemersbegroting" }
                  },
                  [_vm._v(" Aannemersbegroting ")]
                )
              ],
              1
            ),
            _c(
              "v-tabs-items",
              {
                model: {
                  value: _vm.activeTab,
                  callback: function($$v) {
                    _vm.activeTab = $$v
                  },
                  expression: "activeTab"
                }
              },
              [
                _c(
                  "v-tab-item",
                  { attrs: { lazy: true, value: "tab-conclusie" } },
                  [
                    [
                      _c("v-flex", { attrs: { xs12: "" } }, [
                        _c("h2", [_vm._v("SchadeBedrag (incl. BTW)")]),
                        _c(
                          "table",
                          { staticClass: "item__list item__list--table my-4" },
                          [
                            _c("tbody", [
                              _c("tr", { staticClass: "label header__row" }, [
                                _c("th", { attrs: { colspan: "3" } }, [
                                  _vm._v("Schades aan gebouw/werk")
                                ])
                              ]),
                              _c("tr", { staticClass: "list__item" }, [
                                _c("td", [_vm._v("Totaal alle schades")]),
                                _c("td", { staticClass: "balance" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("currency")(
                                        _vm.total_repairs.total_damages
                                      )
                                    )
                                  )
                                ])
                              ]),
                              _c("tr", { staticClass: "list__item" }, [
                                _c("td", [
                                  _vm._v(
                                    "Totaal opstartkosten herstelwerkzaamheden"
                                  )
                                ]),
                                _c("td", { staticClass: "balance" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("currency")(
                                        _vm.total_repairs.total_fixed_costs
                                      )
                                    )
                                  )
                                ])
                              ]),
                              _c("tr", { staticClass: "list__item" }, [
                                _c("td", [_vm._v("Klein werk toeslag")]),
                                _c("td", { staticClass: "balance" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("currency")(
                                        _vm.total_repairs.klein_werk_toeslag
                                      )
                                    )
                                  )
                                ])
                              ]),
                              _c("tr", { staticClass: "list__item subtotal" }, [
                                _c("td", [_vm._v("Totaal schadebedrag")]),
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "balance subheading font-weight-bold"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.total_repairs.total_payment
                                        )
                                      )
                                    )
                                  ]
                                )
                              ])
                            ])
                          ]
                        ),
                        _c("hr", { staticClass: "mb-3" }),
                        _c("h2", [_vm._v("BTW breakdown")]),
                        _c(
                          "table",
                          { staticClass: "item__list item__list--table my-4" },
                          [
                            _c("tbody", [
                              _c("tr", { staticClass: "label header__row" }, [
                                _c("th", { attrs: { colspan: "3" } }, [
                                  _vm._v("Schades aan gebouw/werk")
                                ])
                              ]),
                              _c("tr", { staticClass: "list__item" }, [
                                _c("td", { attrs: { width: "100px" } }, [
                                  _vm._v("zonder BTW")
                                ]),
                                _c("td", { staticClass: "balance" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("currency")(
                                        _vm.total_repairs.gebouw_ex_vat_none
                                      )
                                    )
                                  )
                                ]),
                                _c("td", {
                                  staticClass: "balance",
                                  attrs: { width: "100px" }
                                })
                              ]),
                              _c("tr", { staticClass: "list__item" }, [
                                _c("td", { attrs: { width: "100px" } }, [
                                  _vm._v(
                                    "excl. " +
                                      _vm._s(
                                        _vm.report.calculation_model_vat_low
                                      ) +
                                      "% BTW"
                                  )
                                ]),
                                _c("td", { staticClass: "balance" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("currency")(
                                        _vm.total_repairs.gebouw_ex_vat_low
                                      )
                                    )
                                  )
                                ]),
                                _c("td", {
                                  staticClass: "balance",
                                  attrs: { width: "100px" }
                                })
                              ]),
                              _c("tr", { staticClass: "list__item" }, [
                                _c("td", [_vm._v("excl. 21% BTW")]),
                                _c("td", { staticClass: "balance" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("currency")(
                                        _vm.total_repairs.gebouw_ex_vat_high
                                      )
                                    )
                                  )
                                ]),
                                _c("td", {
                                  staticClass: "balance",
                                  attrs: { width: "100px" }
                                })
                              ]),
                              _c("tr", { staticClass: "list__item subtotal" }, [
                                _c("td", [
                                  _vm._v("Subtotaal schades aan gebouw/werk")
                                ]),
                                _c("td", { staticClass: "balance" }),
                                _c(
                                  "td",
                                  {
                                    staticClass: "balance",
                                    attrs: { width: "100px" }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.total_repairs.gebouw_ex
                                        )
                                      )
                                    )
                                  ]
                                )
                              ])
                            ])
                          ]
                        ),
                        _c(
                          "table",
                          { staticClass: "item__list item__list--table my-4" },
                          [
                            _c("tbody", [
                              _c("tr", { staticClass: "label header__row" }, [
                                _c("th", { attrs: { colspan: "3" } }, [
                                  _vm._v("Gevolgschades")
                                ])
                              ]),
                              _c("tr", { staticClass: "list__item" }, [
                                _c("td", [_vm._v("zonder BTW")]),
                                _c("td", { staticClass: "balance" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("currency")(
                                        _vm.total_repairs.gevolg_ex_vat_none
                                      )
                                    )
                                  )
                                ]),
                                _c("td", {
                                  staticClass: "balance",
                                  attrs: { width: "100px" }
                                })
                              ]),
                              _c("tr", { staticClass: "list__item" }, [
                                _c("td", [
                                  _vm._v(
                                    "excl. " +
                                      _vm._s(
                                        _vm.report.calculation_model_vat_low
                                      ) +
                                      "% BTW"
                                  )
                                ]),
                                _c("td", { staticClass: "balance" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("currency")(
                                        _vm.total_repairs.gevolg_ex_vat_low
                                      )
                                    )
                                  )
                                ]),
                                _c("td", {
                                  staticClass: "balance",
                                  attrs: { width: "100px" }
                                })
                              ]),
                              _c("tr", { staticClass: "list__item" }, [
                                _c("td", [_vm._v("excl. 21% BTW")]),
                                _c("td", { staticClass: "balance" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("currency")(
                                        _vm.total_repairs.gevolg_ex_vat_high
                                      )
                                    )
                                  )
                                ]),
                                _c("td", {
                                  staticClass: "balance",
                                  attrs: { width: "100px" }
                                })
                              ]),
                              _c("tr", { staticClass: "list__item subtotal" }, [
                                _c("td", [_vm._v("Subtotaal Gevolgschades")]),
                                _c("td", { staticClass: "balance" }),
                                _c(
                                  "td",
                                  {
                                    staticClass: "balance",
                                    attrs: { width: "100px" }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.total_repairs.gevolg_ex
                                        )
                                      )
                                    )
                                  ]
                                )
                              ])
                            ])
                          ]
                        ),
                        _c(
                          "table",
                          { staticClass: "item__list item__list--table my-4" },
                          [
                            _c("tbody", [
                              _c("tr", { staticClass: "label header__row" }, [
                                _c("th", { attrs: { colspan: "3" } }, [
                                  _vm._v("BTW")
                                ])
                              ]),
                              _c("tr", { staticClass: "list__item" }, [
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.report.calculation_model_vat_low
                                    ) + "% BTW"
                                  )
                                ]),
                                _c("td", { staticClass: "balance" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.total_repairs.gebouw_vat_low +
                                            _vm.total_repairs.gevolg_vat_low
                                        )
                                      ) +
                                      " "
                                  )
                                ]),
                                _c("td", {
                                  staticClass: "balance",
                                  attrs: { width: "100px" }
                                })
                              ]),
                              _c("tr", { staticClass: "list__item" }, [
                                _c("td", [_vm._v("21% BTW")]),
                                _c("td", { staticClass: "balance" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.total_repairs.gebouw_vat_high +
                                            _vm.total_repairs.gevolg_vat_high
                                        )
                                      ) +
                                      " "
                                  )
                                ]),
                                _c("td", {
                                  staticClass: "balance",
                                  attrs: { width: "100px" }
                                })
                              ]),
                              _c("tr", { staticClass: "list__item subtotal" }, [
                                _c("td", [_vm._v("Totaal BTW")]),
                                _c("td", { staticClass: "balance" }),
                                _c("td", { staticClass: "balance" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.total_repairs.gebouw_vat_low +
                                            _vm.total_repairs.gevolg_vat_low +
                                            _vm.total_repairs.gebouw_vat_high +
                                            _vm.total_repairs.gevolg_vat_high
                                        )
                                      ) +
                                      " "
                                  )
                                ])
                              ])
                            ])
                          ]
                        ),
                        _c(
                          "table",
                          { staticClass: "item__list item__list--table my-4" },
                          [
                            _c("tbody", [
                              _c("tr", { staticClass: "label header__row" }, [
                                _c("th", { attrs: { colspan: "3" } }, [
                                  _vm._v("Toeslagen")
                                ])
                              ]),
                              _c("tr", { staticClass: "list__item" }, [
                                _c("td", [_vm._v("Klein werk toeslag")]),
                                _c("td", { staticClass: "balance" }),
                                _c("td", { staticClass: "balance" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("currency")(
                                        _vm.total_repairs.klein_werk_toeslag
                                      )
                                    )
                                  )
                                ])
                              ]),
                              _c("tr", { staticClass: "list__item subtotal" }, [
                                _c("td", [_vm._v("Totaal toeslagen")]),
                                _c("td", { staticClass: "balance" }),
                                _c("td", { staticClass: "balance" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("currency")(
                                        _vm.total_repairs.klein_werk_toeslag
                                      )
                                    )
                                  )
                                ])
                              ])
                            ])
                          ]
                        ),
                        _c(
                          "table",
                          { staticClass: "item__list item__list--table my-4" },
                          [
                            _c("tr", { staticClass: "list__item subtotal" }, [
                              _c("td", { attrs: { colspan: "3" } }, [
                                _vm._v("Totaal incl. BTW")
                              ]),
                              _c("td", { staticClass: "balance" }),
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "balance subheading font-weight-bold"
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("currency")(
                                        _vm.total_repairs.total_payment
                                      )
                                    )
                                  )
                                ]
                              )
                            ])
                          ]
                        )
                      ])
                    ]
                  ],
                  2
                ),
                _c(
                  "v-tab-item",
                  { attrs: { lazy: true, value: "tab-damages" } },
                  [
                    [
                      _c("v-flex", { attrs: { xs12: "" } }, [
                        _c(
                          "table",
                          { staticClass: "item__list item__list--table my-4" },
                          [
                            _c("tr", { staticClass: "label header__row" }, [
                              _c("td", { attrs: { width: "50px" } }, [
                                _vm._v("#")
                              ]),
                              _c("td", [_vm._v("Schade")]),
                              _c("td", [_vm._v("Beoordeling")]),
                              _c(
                                "td",
                                {
                                  staticClass: "balance",
                                  attrs: { width: "100px" }
                                },
                                [_vm._v("Incl. BTW")]
                              ),
                              _c(
                                "td",
                                {
                                  staticClass: "balance",
                                  attrs: { width: "50px" }
                                },
                                [_vm._v("%")]
                              ),
                              _c(
                                "td",
                                {
                                  staticClass: "balance",
                                  attrs: { width: "100px" }
                                },
                                [_vm._v("Uit te betalen")]
                              )
                            ]),
                            _vm._l(_vm.damages, function(damage, index) {
                              return [
                                _c(
                                  "tr",
                                  {
                                    key: index,
                                    staticClass:
                                      "list__item list__item--white-space-normal"
                                  },
                                  [
                                    _c("td", [_vm._v(_vm._s(index + 1))]),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(_vm.getDamageName(damage, index))
                                      )
                                    ]),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.isCausaalVerband(damage)
                                            ? "Causaal"
                                            : "Niet causaal"
                                        )
                                      )
                                    ]),
                                    _c("td", { staticClass: "balance" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("currency")(
                                            damage.finance.subtotal_in
                                          )
                                        )
                                      )
                                    ]),
                                    _c("td", { staticClass: "balance" }, [
                                      _vm._v(
                                        _vm._s(damage.calculation_percentage) +
                                          "%"
                                      )
                                    ]),
                                    _c("td", { staticClass: "balance" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("currency")(
                                            damage.finance.payout_total
                                          )
                                        )
                                      )
                                    ])
                                  ]
                                )
                              ]
                            }),
                            _c(
                              "tr",
                              {
                                staticClass: "list__item subtotal",
                                attrs: { wrap: "", row: "", "align-center": "" }
                              },
                              [
                                _c("td", { attrs: { colspan: "5" } }, [
                                  _vm._v(
                                    "Totaal schades (zonder opstartkosten)"
                                  )
                                ]),
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "balance subheading font-weight-bold"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.total_repairs.total_damages
                                        )
                                      )
                                    )
                                  ]
                                )
                              ]
                            )
                          ],
                          2
                        )
                      ])
                    ]
                  ],
                  2
                ),
                _c(
                  "v-tab-item",
                  { attrs: { lazy: true, value: "tab-costs" } },
                  [
                    [
                      _c("v-flex", { attrs: { xs12: "" } }, [
                        _c(
                          "table",
                          { staticClass: "item__list item__list--table mb-4" },
                          [
                            _c("tr", { staticClass: "label header__row" }, [
                              _c("th", [
                                _vm._v("Opstartkosten herstelwerkzaamheden")
                              ])
                            ]),
                            _c("tr", { staticClass: "label header__row" }, [
                              _c("td", [_vm._v("Reparatie")]),
                              _c("td", { staticClass: "balance" }, [
                                _vm._v("Excl. BTW")
                              ]),
                              _c("td", { staticClass: "balance" }, [
                                _vm._v("BTW %")
                              ]),
                              _c("td", { staticClass: "balance" }, [
                                _vm._v("Incl. BTW")
                              ])
                            ]),
                            _vm._l(_vm.total_repairs.repair_matrix, function(
                              matrix,
                              repair_matrixIndex
                            ) {
                              return [
                                _c(
                                  "tr",
                                  {
                                    key: "repair_matrix" + repair_matrixIndex,
                                    staticClass: "label b-bottom primary--text"
                                  },
                                  [
                                    _c("td", { attrs: { colspan: "4" } }, [
                                      _vm._v(_vm._s(matrix.name))
                                    ])
                                  ]
                                ),
                                _vm._l(matrix.repairs, function(
                                  repair,
                                  repairIndex
                                ) {
                                  return _c(
                                    "tr",
                                    {
                                      key:
                                        "repair_" +
                                        repairIndex +
                                        repair_matrixIndex,
                                      staticClass:
                                        "list__item list__item--white-space-normal",
                                      attrs: {
                                        wrap: "",
                                        row: "",
                                        "align-center": ""
                                      }
                                    },
                                    [
                                      _c("td", [
                                        _c("div", [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "d-block font-weight-bold"
                                            },
                                            [_vm._v(_vm._s(repair.label))]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "d-block" },
                                            [_vm._v(_vm._s(repair.description))]
                                          )
                                        ])
                                      ]),
                                      _c("td", { staticClass: "balance" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("currency")(
                                              repair.fixed_costs
                                            )
                                          )
                                        )
                                      ]),
                                      _c("td", { staticClass: "balance" }, [
                                        _vm._v(_vm._s(repair.vat))
                                      ]),
                                      _c("td", { staticClass: "balance" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("currency")(
                                              repair.fixed_costs_incl
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                })
                              ]
                            }),
                            _c(
                              "tr",
                              {
                                staticClass:
                                  "list__item list__item--white-space-normal subtotal",
                                attrs: { wrap: "", row: "", "align-center": "" }
                              },
                              [
                                _c("td", { attrs: { colspan: "3" } }, [
                                  _vm._v(
                                    "Totaal opstartkosten herstelwerkzaamheden"
                                  )
                                ]),
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "balance subheading font-weight-bold",
                                    attrs: { width: "100px" }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.total_repairs.total_fixed_costs
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            )
                          ],
                          2
                        )
                      ])
                    ]
                  ],
                  2
                ),
                _c(
                  "v-tab-item",
                  { attrs: { lazy: true, value: "tab-repairs" } },
                  [
                    [
                      _c("v-flex", { attrs: { xs12: "" } }, [
                        _c(
                          "table",
                          { staticClass: "item__list item__list--table my-4" },
                          [
                            _c("thead", [
                              _c("tr", { staticClass: "label header__row" }, [
                                _c("th", { attrs: { width: "50px" } }, [
                                  _vm._v("Model")
                                ]),
                                _c("th", [_vm._v("Schade / Reparatie")]),
                                _c("th", { staticClass: "balance" }, [
                                  _vm._v("Hvh")
                                ]),
                                _c("th", { staticClass: "balance" }, [
                                  _vm._v("Stukprijs")
                                ]),
                                _c("th", { staticClass: "balance" }, [
                                  _vm._v("Excl. BTW")
                                ]),
                                _c("th", { staticClass: "balance" }, [
                                  _vm._v("Incl. BTW")
                                ]),
                                _c("th", { staticClass: "balance" }, [
                                  _vm._v("BTW%")
                                ]),
                                _c("th", { staticClass: "balance" }, [
                                  _vm._v("Uitbetalen")
                                ])
                              ])
                            ]),
                            _c(
                              "tbody",
                              [
                                _vm._l(_vm.damages, function(
                                  damage,
                                  damageIndex
                                ) {
                                  return [
                                    _c(
                                      "tr",
                                      {
                                        key: "damages_" + damageIndex,
                                        staticClass: "damagename"
                                      },
                                      [
                                        _c("td", { staticClass: "balance" }),
                                        _c("td", { attrs: { colspan: "7" } }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDamageName(
                                                damage,
                                                damageIndex
                                              )
                                            )
                                          )
                                        ])
                                      ]
                                    ),
                                    _vm._l(damage.repairs, function(
                                      repair,
                                      repairIndex
                                    ) {
                                      return [
                                        repair.form === "custom"
                                          ? [
                                              _c(
                                                "tr",
                                                {
                                                  key:
                                                    "repair_" +
                                                    repairIndex +
                                                    damageIndex,
                                                  staticClass:
                                                    "list__item list__item--white-space-normal"
                                                },
                                                [
                                                  _c(
                                                    "td",
                                                    { staticClass: "balance" },
                                                    [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { bottom: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  return [
                                                                    repair.form ===
                                                                    "custom"
                                                                      ? _c(
                                                                          "v-icon",
                                                                          _vm._g(
                                                                            {
                                                                              attrs: {
                                                                                color:
                                                                                  "primary"
                                                                              }
                                                                            },
                                                                            on
                                                                          ),
                                                                          [
                                                                            _vm._v(
                                                                              "info"
                                                                            )
                                                                          ]
                                                                        )
                                                                      : _vm._e()
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "Aangepaste calculatie"
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(repair.custom_name)
                                                    )
                                                  ]),
                                                  _c(
                                                    "td",
                                                    { staticClass: "balance" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(repair.quantity)
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    { staticClass: "balance" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("currency")(
                                                            repair.custom_unitprice
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    { staticClass: "balance" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("currency")(
                                                            repair.total
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    { staticClass: "balance" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("currency")(
                                                            repair.total_incl_vat
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    { staticClass: "balance" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          repair.custom_vat
                                                        ) + "%"
                                                      )
                                                    ]
                                                  ),
                                                  _c("td", {
                                                    staticClass: "balance"
                                                  })
                                                ]
                                              )
                                            ]
                                          : [
                                              _c(
                                                "tr",
                                                {
                                                  key:
                                                    "repair_" +
                                                    repairIndex +
                                                    damageIndex,
                                                  staticClass:
                                                    "list__item list__item--white-space-normal"
                                                },
                                                [
                                                  _c(
                                                    "td",
                                                    { staticClass: "balance" },
                                                    [
                                                      repair.type &&
                                                      repair.type
                                                        .calculation_model
                                                        ? _c("span", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  repair.type
                                                                    .calculation_model
                                                                    .name || ""
                                                                ) +
                                                                " "
                                                            )
                                                          ])
                                                        : _vm._e()
                                                    ]
                                                  ),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        repair.form !== "custom"
                                                          ? repair.type.name
                                                          : repair.custom_name
                                                      )
                                                    )
                                                  ]),
                                                  _c(
                                                    "td",
                                                    { staticClass: "balance" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("quantity")(
                                                            repair.quantity
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    { staticClass: "balance" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("currency")(
                                                            repair.unitprice
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    { staticClass: "balance" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("currency")(
                                                            repair.total
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    { staticClass: "balance" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("currency")(
                                                            repair.total_incl_vat
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    { staticClass: "balance" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(repair.vat) + "%"
                                                      )
                                                    ]
                                                  ),
                                                  _c("td", {
                                                    staticClass: "balance"
                                                  })
                                                ]
                                              )
                                            ]
                                      ]
                                    }),
                                    _c(
                                      "tr",
                                      {
                                        key:
                                          "damages_" + damageIndex + "_total",
                                        staticClass:
                                          "list__item list__item--white-space-normal subtotal"
                                      },
                                      [
                                        _c("td", { staticClass: "balance" }),
                                        _c("td", [
                                          _vm._v(
                                            "BTW toepassen: " +
                                              _vm._s(
                                                damage.apply_vat ? "Ja" : "Nee"
                                              )
                                          )
                                        ]),
                                        _c("td", { staticClass: "balance" }, [
                                          _vm._v(
                                            _vm._s(
                                              damage.calculation_percentage
                                            ) + "%"
                                          )
                                        ]),
                                        _c("td", { staticClass: "balance" }, [
                                          _vm._v("Subtotaal")
                                        ]),
                                        _c("td", { staticClass: "balance" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("currency")(
                                                damage.finance.subtotal_ex
                                              )
                                            )
                                          )
                                        ]),
                                        _c("td", { staticClass: "balance" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("currency")(
                                                damage.finance.subtotal_in
                                              )
                                            )
                                          )
                                        ]),
                                        _c("td", { staticClass: "balance" }),
                                        _c("td", { staticClass: "balance" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("currency")(
                                                damage.finance.payout_total
                                              )
                                            )
                                          )
                                        ])
                                      ]
                                    )
                                  ]
                                })
                              ],
                              2
                            ),
                            _c("tfoot", [
                              _c(
                                "tr",
                                {
                                  staticClass: "damagename",
                                  attrs: {
                                    wrap: "",
                                    row: "",
                                    "align-center": ""
                                  }
                                },
                                [
                                  _c("td"),
                                  _c("td", { attrs: { colspan: "6" } }, [
                                    _vm._v("Totaal schades gebouw incl. BTW")
                                  ]),
                                  _c("td", { staticClass: "balance" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.total_repairs.total_damages
                                        )
                                      )
                                    )
                                  ])
                                ]
                              )
                            ])
                          ]
                        )
                      ])
                    ]
                  ],
                  2
                ),
                _c(
                  "v-tab-item",
                  { attrs: { lazy: true, value: "tab-aannemersbegroting" } },
                  [
                    [
                      _c("v-flex", { attrs: { xs12: "" } }, [
                        _c(
                          "table",
                          { staticClass: "item__list item__list--table mb-4" },
                          [
                            _c("tr", { staticClass: "label header__row" }, [
                              _c("td", [_vm._v("Reparatie")]),
                              _c("td", { staticClass: "balance" }, [
                                _vm._v("Hoeveelheid")
                              ]),
                              _c("td", { staticClass: "balance" }, [
                                _vm._v("Eenheidsprijs")
                              ]),
                              _c("td", { staticClass: "balance" }, [
                                _vm._v("Variabelekosten")
                              ]),
                              _c("td", { staticClass: "balance" }, [
                                _vm._v("Opstartkosten")
                              ]),
                              _c("td", { staticClass: "balance" }, [
                                _vm._v("Excl. BTW")
                              ]),
                              _c("td", { staticClass: "balance" }, [
                                _vm._v("BTW %")
                              ]),
                              _c("td", { staticClass: "balance" }, [
                                _vm._v("Incl. BTW")
                              ])
                            ]),
                            _vm._l(_vm.total_repairs.repair_matrix, function(
                              matrix,
                              repair_matrixIndex
                            ) {
                              return [
                                _c(
                                  "tr",
                                  {
                                    key: "repair_matrix" + repair_matrixIndex,
                                    staticClass: "label b-bottom primary--text"
                                  },
                                  [
                                    _c("td", { attrs: { colspan: "4" } }, [
                                      _vm._v(_vm._s(matrix.name))
                                    ])
                                  ]
                                ),
                                _vm._l(matrix.repairs, function(
                                  repair,
                                  repairIndex
                                ) {
                                  return _c(
                                    "tr",
                                    {
                                      key:
                                        "repair_" +
                                        repairIndex +
                                        repair_matrixIndex,
                                      staticClass:
                                        "list__item list__item--white-space-normal",
                                      attrs: {
                                        wrap: "",
                                        row: "",
                                        "align-center": ""
                                      }
                                    },
                                    [
                                      _c("td", [
                                        _c("div", [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "d-block font-weight-bold"
                                            },
                                            [_vm._v(_vm._s(repair.label))]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "d-block" },
                                            [_vm._v(_vm._s(repair.description))]
                                          )
                                        ])
                                      ]),
                                      _c("td", { staticClass: "balance" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("quantity")(
                                              repair.calculation_quantity
                                            )
                                          )
                                        )
                                      ]),
                                      _c("td", { staticClass: "balance" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("currency")(repair.unitprice)
                                          )
                                        )
                                      ]),
                                      _c("td", { staticClass: "balance" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("currency")(
                                              repair.variable_costs
                                            )
                                          )
                                        )
                                      ]),
                                      _c("td", { staticClass: "balance" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("currency")(
                                              repair.fixed_costs
                                            )
                                          )
                                        )
                                      ]),
                                      _c("td", { staticClass: "balance" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("currency")(
                                              repair.subtotal_ex
                                            )
                                          )
                                        )
                                      ]),
                                      _c("td", { staticClass: "balance" }, [
                                        _vm._v(_vm._s(repair.vat))
                                      ]),
                                      _c("td", { staticClass: "balance" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("currency")(
                                              repair.subtotal_in
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                })
                              ]
                            }),
                            _c(
                              "tr",
                              {
                                staticClass:
                                  "list__item list__item--white-space-normal subtotal",
                                attrs: { wrap: "", row: "", "align-center": "" }
                              },
                              [
                                _c("td", { attrs: { colspan: "3" } }, [
                                  _vm._v(
                                    "Totaal begroting (incl. afrondverschillen)"
                                  )
                                ]),
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "balance subheading font-weight-bold"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.totalRepairMatrix.variable_costs
                                        )
                                      )
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "balance subheading font-weight-bold"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.totalRepairMatrix.fixed_costs
                                        )
                                      )
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "balance subheading font-weight-bold"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.totalRepairMatrix.subtotal_ex
                                        )
                                      )
                                    )
                                  ]
                                ),
                                _c("td"),
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "balance subheading font-weight-bold"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.totalRepairMatrix.subtotal_in
                                        )
                                      )
                                    )
                                  ]
                                )
                              ]
                            )
                          ],
                          2
                        ),
                        _c("p", [
                          _c("span", [
                            _c("img", {
                              staticClass: "deadlineIcon",
                              attrs: {
                                src: require("@/assets/images/icons/request-rejected.svg")
                              }
                            })
                          ]),
                          _vm._v(" LET OP ")
                        ]),
                        _c("p", [
                          _vm._v(
                            " Deze berekening geeft alleen een juist beeld bij een calculatiemodel v5. "
                          ),
                          _c("br"),
                          _vm._v(
                            " Bij voorgaande modellen varieert de eenheidsprijs per schade en kun je de reparaties niet groeperen. "
                          )
                        ]),
                        _c("p", [
                          _vm._v(
                            " Voor de beeldvorming laat hij toch de berekening zien, met de corresponderende eenheidsprijs (staffelprijs) uit het oudere model. Het bedrag kun je dus niet koppelen aan het bedrag in het rapport. "
                          )
                        ])
                      ])
                    ]
                  ],
                  2
                )
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { Component, Vue, Model } from 'vue-property-decorator';
import { Report as ReportModel, Report } from '@/models/Report';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { Damage } from '@/models/Damage';
import { userRoles } from '@/models/User';

@Component<SchadeBedrag>({})
export default class SchadeBedrag extends Vue {
  public $pageTitle = 'Schadebedrag';

  @Model('report', { default: () => new ReportModel() })
  protected report!: ReportModel;

  protected isLoading = true;

  protected useCalculationModel5 = false;

  protected total_repairs: TotalRepairs | null = null;

  protected damages: Damage[] = [];

  protected userRoles = userRoles;

  protected activeTab = 'tab-conclusie';

  protected totalRepairMatrix = {
    variable_costs: 0,
    fixed_costs: 0,
    subtotal_ex: 0,
    subtotal_in: 0,
  };

  protected async mounted() {
    this.isLoading = true;
    await this.loadTotalRepairs();
    if (this.$store.state.Auth.has_management_info) {
      await this.loadDamages();
    }

    this.isLoading = false;
  }

  protected getDamageName(damage: Damage, index: number) {
    const defaultName = `Schade ${index + 1}`;
    if (! damage || ! damage.name) {
      return defaultName;
    }

    const name = damage.name.replace(/\s/g, '').length ? damage.name : defaultName;
    const revised = damage.answers && damage.answers.MarkerenAlsHerzien === 'Ja' ? '(herzien)' : '';

    return `${index + 1} ${name} ${revised}`;
  }

  protected async refreshCalculations() {
    await this.loadTotalRepairs();
    await this.loadDamages();
  }

  protected async loadDamages() {
    this.damages = await new Damage()
      .refactor()
      .include(['repairs', 'finance'])
      .filter({
        report: this.report.uuid,
        convert_v5: this.useCalculationModel5,
      })
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async loadTotalRepairs() {
    const reportCopy = await new Report()
      .include(['total_repairs'])
      .filter({
        convert_v5: this.useCalculationModel5,
      })
      .find(this.report.uuid)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    this.total_repairs = reportCopy.total_repairs;

    this.calculateTotalRepairMatrix();
  }

  protected async calculateTotalRepairMatrix() {
    this.totalRepairMatrix = {
      variable_costs: 0,
      fixed_costs: 0,
      subtotal_ex: 0,
      subtotal_in: 0,
    };

    if (! this.total_repairs || ! this.total_repairs.repair_matrix) return;

    this.total_repairs.repair_matrix.forEach((matrix: any) => {
      matrix.repairs.forEach((repair: any) => {
        this.totalRepairMatrix.variable_costs += repair.variable_costs;
        this.totalRepairMatrix.fixed_costs += repair.fixed_costs;
        this.totalRepairMatrix.subtotal_ex += repair.subtotal_ex;
        this.totalRepairMatrix.subtotal_in += repair.subtotal_in;
      });
    });
  }

  protected isCausaalVerband(damage: Damage) {
    if (damage.answers.MijnbouwCausaalVerband == 'Ja') {
      return true;
    }
    // Gevolgschade
    if (damage.answers.MijnbouwOorzaakGevolg == 'Ja') {
      return true;
    }
    // Oude vraag:
    return damage.answers.MijnbouwOorzaak == 'Ja' || damage.answers.ErgerDoorMijnbouw == 'Ja';
  }
}

export interface TotalRepairs {
  gebouw_ex_vat_none: number;
  gebouw_ex_vat_low: number;
  gebouw_ex_vat_high: number;
  gebouw_ex: number;
  gebouw_vat_low: number;
  gebouw_vat_high: number;
  gebouw_in: number;
  gevolg_ex_vat_none: number;
  gevolg_ex_vat_low: number;
  gevolg_ex_vat_high: number;
  gevolg_ex: number;
  gevolg_vat_low: number;
  gevolg_vat_high: number;
  gevolg_in: number;
  klein_werk_toeslag: number;
  total_in: number;
  index_percentage: number;
  index_amount: number;
  total_payment: number;
  repair_matrix: any;
}
